import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { client } from '../prismic-configuration'
import NotFound from './NotFound'
import { Content, CovidText, IndentedText, Section, SectionTitle } from '../styles/AppointmentsFees'
import { Divider, Button, Spinner } from '../components'
import { useHistory } from 'react-router-dom'

const AppointmentsFees = ({ setIsHome }) => {
    const [doc, setDocData] = useState(null)
    const [notFound, toggleNotFound] = useState(false)
    const history = useHistory()
    const handleClick = () => history.push('/contact-us')

    useEffect(() => {
        setIsHome(false)
        const fetchData = async () => {
            const result = await client.getByUID('appointments_fees', 'appointments-fees')

            if (result) {
                return setDocData(result)
            } else {
                console.warn('Page document not found. Make sure it exists in your Prismic repository')
                toggleNotFound(true)
            }
        }
        fetchData()
    }, [])
    
    if (doc) {
        return (
            <div className="page">
                <h1>{RichText.asText(doc.data.title)}</h1>
                <Content>
                    <Section>
                        <SectionTitle>{RichText.asText(doc.data.section1_title)}</SectionTitle>
                        <RichText render={doc.data.section1_heading_text} />
                        <IndentedText>
                            <RichText render={doc.data.section1_text} />
                        </IndentedText>
                    </Section>
                    <Divider></Divider>
                    <Section>
                        <SectionTitle>{RichText.asText(doc.data.section2_title)}</SectionTitle>
                        <RichText render={doc.data.section2_text}/>
                        <RichText render={doc.data.document_link}/>
                        <RichText render={doc.data.section2_2_text}/>
                        <RichText render={doc.data.cta1} />
                        <Button onClick={handleClick}>Contact Us</Button>
                    </Section>
                    <Divider></Divider>
                    <Section>
                        <SectionTitle>{RichText.asText(doc.data.section3_title)}</SectionTitle>
                        <RichText render={doc.data.section3_text} />
                        <RichText render={doc.data.cta2} />
                        <Button onClick={handleClick}>Contact Us</Button>
                    </Section>
                    <Divider></Divider>
                    <Section>
                        <SectionTitle>{RichText.asText(doc.data.section4_title)}</SectionTitle>
                        <RichText render={doc.data.section4_text} />
                    </Section>
                </Content>
            </div>
        )
    } else if (notFound) {
        return <NotFound />
    } else {
        return (
            <div className="page">
                <Spinner />
            </div>
        )
    }
}

export default AppointmentsFees